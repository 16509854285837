<template>
  <div>
    <list-template
      :loading="loading"
      :search-config="searchConfig"
      :is-check-input-box="false"
      @onSearch="onSearch"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :current-page="page"
      @onChangePage="handleCurrentChange"
      @onHandle="handleEdit"
    ></list-template>
  </div>
</template>
<script>
import {mapState} from 'vuex'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      search: {},
      searchConfig: [
        {
          prop: 'student_name',
          placeholder: '搜索学生姓名'
        },
        {
          prop: 'customer_name',
          placeholder: '搜索家长姓名'
        },
        {
          prop: 'status',
          tag:"select",
          placeholder: '筛选订单状态',
          options:[
            {value: 0, label: '待支付'},
            {value: 1, label: '待签约'},
            {value: 2, label: '已取消'},
            {value: 3, label: '已签约'}
          ],
        },
        {
          prop: 'time_pay',
          tag:"daterange",
          placeholder: '筛选支付成功时间',
          start: '开始时间',
          end: '结束时间'
        },
        {
          prop: 'school_id',
          tag:"select",
          placeholder: '筛选报名学校',
          options:[],
          label:"school_name",
          value:"id"
        },
        {
          prop: 'school_year',
          tag:"select",
          placeholder: '筛选入学年份',
          options:[],
        },
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,

      tableConfig: [
        { prop: 'status', label: '订单状态' },
        { prop: 'customer_phone', label: '签约手机号' },
        { prop: 'student_name', label: '学生姓名' },
        { prop: 'customer_name', label: '家长姓名' },
        { prop: 'school_year', label: '入学年份' },
        { prop: 'school_name', label: '报名校区' },
        { prop: 'pay_time', label: '支付成功时间' },
        {
          prop: 'handle',
          label: '操作',
          width: '120rem',
          handle: true,
          render(row) {
            return ['查看']
          }
        }
      ]
    }
  },
  created() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.getSchool()
    this.getYear()
  },
  activated() {
    this.getData()
    this.getSchool()
    this.getYear()
  },
  computed: {
    ...mapState(['page'])
  },
  methods: {
    getYear(){
      this.searchConfig[5].options = []
      let year = new Date().getFullYear() - 2
      for (let i = 0; i < 5; i++) {
        let obj = {
          value: year,
          label: year + '年'
        }
        this.searchConfig[5].options.push(obj)
        year++
      }
    },

    getSchool(){
      this.$_register('api/recruit/common/consulting-school').then(res => {
        this.searchConfig[4].options = res.data.data.entity_school || []
      })
    },

    getData() {
      this.loading = true
      let params = { ...this.search, page: this.page }
      this.tableData = []
      this.$_register('api/recruit/promise-pass/list', { params })
        .then(res => {
          let data = res.data.data
          this.tableData = data.list
          this.total = data.page.total
        })
        .finally(() => {
          this.loading = false
        })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData(val)
    },

    handleEdit(row, text) {
        this.$router.push('./details?id=' + row.order_id)
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit('setPage', val)
      //拉取数据
      this.getData()
    }
  }
}
</script>
<style lang="scss" scoped>
.role-list {
  padding-top: 24rem;
}

.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}

.table {
  margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}

.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}

.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
